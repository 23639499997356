<template>
  <CardToolbar
    title="Discover Quickly"
    description="Discover your Spotify data"
    :show-toolbar-buttons="false"
    class="pb-9"
  >
    <template v-slot:body>
      <h4 class="text-center subheading" v-if="accessToken">
        <DiscoverMenu />
      </h4>

      <div v-else class="mb-5 text-center">
        <h6 class="subheading fw-light mb-3">
          You are not logged in to Spotify, Please login
        </h6>
        <a class="btn btn-sm btn-success me-3" @click="loginToSpotify"
          >Login to Spotify</a
        >
      </div>
    </template>
  </CardToolbar>
  <div v-if="accessToken">
    <el-row :gutter="20">
      <el-col :span="18">
        <keep-alive>
          <component
            class="mr-30"
            @mouseleave="hideTrackComponent"
            :is="componentName"
            v-bind="currentProps"
            @add-component="addComponentToView($event, 0)"
          />
        </keep-alive>
        <component
          @mouseleave="hideTrackComponent"
          @add-component="addComponentToView($event, index + 1)"
          class="mt-5"
          v-for="(component, index) in components"
          :key="index"
          :is="component.componentName"
          v-bind:class="component.id"
          :ref="component.id"
          v-bind="component.currentProps"
        />
      </el-col>
      <el-col :span="6">
        <TrackPlayer />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";

import CardToolbar from "../../common/components/CardToolbar";
import DiscoverMenu from "../components/DiscoverMenu";
import DiscoverArtistDetail from "../components/DiscoverArtistDetail";
import DiscoverPlaylist from "../components/DiscoverPlaylist";
import DiscoverAlbum from "../components/DiscoverAlbum";
import DiscoverAlbumWithTracks from "../components/DiscoverAlbumWithTracks";
import Genres from "../components/Genres";
import MyPlaylists from "../components/MyPlaylists";
import MyAlbums from "../components/MyAlbums";
import NewReleases from "../components/NewReleases";
import Search from "../components/Search";
import SpotifyPlaylists from "../components/SpotifyPlaylists";
import TrackPlayer from "../components/TrackPlayer";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "Discover",
  components: {
    CardToolbar,
    DiscoverMenu,
    DiscoverArtistDetail,
    DiscoverAlbum,
    DiscoverPlaylist,
    DiscoverAlbumWithTracks,
    Genres,
    MyPlaylists,
    MyAlbums,
    NewReleases,
    Search,
    SpotifyPlaylists,
    TrackPlayer,
  },
  setup() {
    const components = ref([]);
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const currentlySelectedMenu = computed(
      () => store.getters["DiscoverModule/getCurrentlySelectedMenu"]
    );
    const componentName = computed(() =>
      currentlySelectedMenu.value.replace(/\s/g, "")
    );
    const searchQueryDiscoverTool = computed(() => {
      return store.getters["EventsModule/getArtistSearchQuery"];
    });

    const searchQueryURL = computed(() => {
      return route.query.search;
    });

    const searchedGenre = computed(() => {
      return route.query.genre;
    });

    const currentProps = computed(
      () =>
        componentName.value === "Search" && {
          query: searchQueryURL.value,
        }
    );
    const spotifyUser = computed(
      () => store.getters["DiscoverModule/getSpotifyUser"]
    );
    const accessToken = computed(
      () => store.getters["SpotifyAccessTokenHelperModule/getAccessToken"]
    );

    const addComponentToView = (componentData, componentIndex) => {
      if (componentIndex === 0) {
        components.value = [];
      } else if (componentIndex < components.value.length) {
        components.value.splice(componentIndex);
      }
      componentData.forEach((data) => {
        components.value.push({
          componentName: data.component,
          currentProps: {
            id: data.id,
            index: components.value.length,
            componentIndex,
          },
          id: `${data.component}${components.value.length}`,
        });
      });
    };

    const generateRandomString = function (length) {
      let text = "";
      const possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }
      return text;
    };

    const hideTrackComponent = async () => {
      await store.dispatch("TrackPlaybackModule/stopSong");
    };

    const loginToSpotify = () => {
      const clientId = process.env.VUE_APP_SPOTIFY_CLIENT_ID;
      const response_type = "code";
      const scope =
        "user-read-private user-read-email user-follow-modify user-follow-read user-library-modify user-library-read playlist-modify-private playlist-read-private playlist-modify-public";
      const redirectUri = window.origin + "/callback-spotify";
      const state = generateRandomString(16);
      const redirectAfterLogin = route.query.search
        ? `/discover?search=${route.query.search}`
        : route.query.genre
        ? `/discover?genre=${route.query.genre}`
        : `/discover`;
      store.commit(
        "SpotifyAccessTokenHelperModule/SET_REDIRECT_PATH_AFTER_LOGIN",
        redirectAfterLogin
      );
      window.location.href = `https://accounts.spotify.com/authorize?response_type=${response_type}&client_id=${clientId}&scope=${scope}&redirect_uri=${redirectUri}&state=${state}`;
    };

    const showGenreComponent = async (id) => {
      if (accessToken.value && id) {
        await store.dispatch("DiscoverModule/changeSelectedMenu", "Genres");
        await addComponentToView(
          [
            {
              component: "DiscoverPlaylist",
              id,
            },
          ],
          0
        );
        await router.push("/discover");
      }
    };

    onMounted(async () => {
      const toggleElement = document.getElementById("kt_aside_toggle");
      if (toggleElement && !toggleElement.classList.contains("active")) {
        toggleElement.click();
      }
      await store.dispatch("TrackPlaybackModule/createPlayer");
      await showGenreComponent(searchedGenre.value);
    });

    watch(
      () => searchQueryURL.value,
      () => {
        components.value = [];
      }
    );

    watch(
      () => searchedGenre.value,
      async (value) => {
        await showGenreComponent(value);
      },
      { immediate: true }
    );

    // watch(
    //   () => searchQueryDiscoverTool.value,
    //   async (value) => {
    //     if (value && value !== "") {
    //       router.push(`/discover?search=${value}`);
    //       await store.dispatch("EventsModule/searchArtist", "");
    //     }
    //   }
    // );

    watch(
      () => currentlySelectedMenu.value,
      () => {
        components.value = [];
      }
    );
    return {
      accessToken,
      addComponentToView,
      currentlySelectedMenu,
      componentName,
      currentProps,
      components,
      hideTrackComponent,
      loginToSpotify,
      spotifyUser,
    };
  },
};
</script>

<style scoped>
.mr-30 {
  margin-right: 30px;
}
</style>
